<template>
  <CCard>
    <CCardHeader class="bg-orange text-white"
      >บัญชี Litter Bank ของฉัน</CCardHeader
    >
    <CCardBody v-if="rows">
      <CRow>
        <CCol lg="4" class="text-primary">
          รวมยอดฝาก: {{ sumDeposit.toLocaleString() }} บาท
        </CCol>
        <CCol lg="4" class="text-danger">
          รวมยอดเงินถอน: {{ sumWithdraw.toLocaleString() }} บาท
        </CCol>
        <CCol lg="4">
          คงเหลือ: {{ (sumDeposit + sumWithdraw).toLocaleString() }} บาท
        </CCol>
      </CRow>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{ enabled: true }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'ddetail_id', type: 'desc' },
        }"
        :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
      >
        >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'garbage_price_name'">
            <CBadge color="danger" v-if="props.row.garbage_price_id == 6">{{
              props.row.garbage_price_name
            }}</CBadge>
            <CBadge color="success" v-else>{{
              props.row.garbage_price_name
            }}</CBadge>
          </span>
          <span v-else-if="props.column.field == 'user_fname'">
            <router-link :to="'/statByTeacher/' + props.row.teacher_id">
              {{ props.row.user_fname }}
            </router-link>
          </span>
          <span v-else-if="props.column.field == 'tc_citeria_name'">
            <router-link :to="'/statByReason/' + props.row.tc_citeria_no">
              {{ props.row.tc_citeria_name }}
            </router-link>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>

          <span v-if="props.column.field == 'actions'">
            <div v-if="props.row.teacher_id == userData.user_id">
              <button
                class="btn btn-warning"
                type="button"
                @click="openModal(props.row.att_id)"
              >
                edit
              </button>
              <button
                class="btn btn-danger"
                @click="removeAtt(props.row.att_id)"
                style="margin-top: 0.2rem"
              >
                delete
              </button>
            </div>
          </span>
        </template>
      </vue-good-table>
      <CRow style="margin-top: 20px">
        <CCol
          ><div style="text-align: right">
            <button @click="onExport" class="btn btn-secondary">Export</button>
            <!-- เพิ่มปุ่ม Export -->
          </div></CCol
        >
      </CRow>
    </CCardBody>
    <div v-else class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </CCard>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import SltSubject from "./SltSubject.vue";
import SltStudent from "./SltStudent.vue";
import ChkPeriod from "./ChkPeriod.vue";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    SltSubject,
    SltStudent,
    ChkPeriod,
  },
  data() {
    return {
      columns: [
        {
          label: "#",
          field: "ddetail_id",
          type: "number",
        },
        {
          label: "วันที่ฝาก",
          field: "ddetail_created",
        },
        {
          label: "ชื่อผู้ทำรายการ",
          field: "customer_name",
        },
        {
          label: "รายการ",
          field: "garbage_price_name",
        },
        {
          label: "ราคา/หน่วย (บาท)",
          field: "ddetail_price",
          type: "number",
        },
        {
          label: "น้ำหนัก (กิโลกรัม)",
          field: "ddetail_quantity",
          type: "number",
        },
        {
          label: "รวม (บาท)",
          field: "total",
          type: "number",
        },

        {
          label: "เจ้าหน้าที่",
          field: "creator_name",
        },
      ],
      rows: null,
      rowsExcel: null,
      userData: this.$cookies.get("user"),
      selectedRow: null,
      responseContent: null,
      darkModal: false,
      modalCreate: false,
      modalEdit: false,
      modalAlert: false,
      msgAlert: null,
      pictUrl1: "-",
      pictUrl2: "-",
      pictUrl3: "-",
      pictUrl4: "-",
      fileLink1: "-",
      fileLink2: "-",
      fileLink3: "-",
      fileLink4: "-",
      pic1New: null,
      pic2New: null,
      pic3New: null,
      pic4New: null,
      file1New: null,
      file2New: null,
      file3New: null,
      file4New: null,
      sumDeposit: 0,
      sumWithdraw: 0,
    };
  },
  mounted() {
    //console.log(this.userData);

    //FETCH DEPOSIT BY STUDENT
    let formData = new FormData();
    formData.append("txtAction", "getDepositLogByDepositor");
    formData.append("depositorId", this.userData.stud_id);
    formData.append("schoolCode", this.userData.std_school);

    this.axios
      .post(this.$hostUrl + "php_action/depositAPI.php", formData)
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
        this.rowsExcel = response.data.mainExcel;
        this.sumDeposit = response.data.sumDeposit;
        this.sumWithdraw = response.data.sumWithdraw;
      });
  },
  methods: {
    openModal(attId) {
      this.darkModal = true;
      this.selectedAttId = attId;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&txtAttId=" +
            this.selectedAttId
        )
        .then((response) => {
          this.selectedData = response.data.mainData;
          this.selectedCiteria = { code: this.selectedData.tc_citeria_no };
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => {});
    },
    updateData() {
      //SEND DATA
      const form = document.getElementById("formUpdateAbs");
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("sltCiteria", this.selectedCiteria.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            this.responseContent = response.data.error;
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },
    setSelected(value) {
      this.selectedCiteria = value;
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/attendanceAPI.php?txtAction=delete&attId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
  },
};
</script>
